import "./Styles/StyleHome.css";
import "./Styles/StyleAbout.css";
import "./Styles/StylePageNotFound.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import AboutUS from "./Pages/AboutUS";
import ButtonTop from "./components/ButtonTop";
import Home from "./Pages/Home";
import PageNOtFound from "./Pages/PageNOtFound";

function App() {
  return (
    <main >
      <ButtonTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUS />} />
        <Route path="*" element={<PageNOtFound />} />
      </Routes>
    </main>
  );
}

export default App;
