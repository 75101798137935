import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Submenu from "../components/Submenu";
import Sidebar from "../components/Sidebar";
import MetiNumbers from "../components/MetiNumbers";
import Clinets from "../components/Clients";
import ServicePackges from "../components/ServicePackges";
import LabelingServices from "../components/LabelingServices";
import AISolutions from "../components/AISolutions";
import Industries from "../components/Industries";
import UseCases from "../components/UseCases";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import { useGloblaContext } from "../context/AutoContext";

function Home() {
  const { closeSidebar } = useGloblaContext();
  useEffect(() => {
    closeSidebar();
  }, []);
  return (
    <>
      <Navbar />
      <Header />
      <Submenu />
      <Sidebar />
      <MetiNumbers />
      <Clinets />
      <ServicePackges />
      <LabelingServices />
      <AISolutions />
      <Industries />
      <UseCases />
      <ContactUs />
      <Footer />
    </>
  );
}

export default Home;
