import React from "react";
import logo from "../images/Logos/meti2.png";
import { Link } from "react-router-dom";
import { useGloblaContext } from "../context/AutoContext";
function Footer({ aboutUs }) {
  const { scrolling } = useGloblaContext();

  return (
    <footer id="footer" className="footer">
      <div className="w-container">
        <div className="footer-flex-container">
          {aboutUs ? (
            <Link to="/" className="footer-logo-link w-inline-block">
              <img src={logo} width="162" alt="logo" className="footer-image" />
            </Link>
          ) : (
            <a
              href="#home"
              className="footer-logo-link w-inline-block"
              onClick={(e) => scrolling(e)}
            >
              <img src={logo} width="162" alt="logo" className="footer-image" />
            </a>
          )}

          {!aboutUs && (
            <div className="div-block-17">
              <h2 className="footer-heading">Our Services</h2>
              <ul className="w-list-unstyled">
                <li>
                  <a
                    href="#services-packages"
                    className="footer-link"
                    onClick={(e) => scrolling(e)}
                  >
                    Services Packages
                  </a>
                </li>
                <li>
                  <a
                    href="#labeling-services"
                    className="footer-link"
                    onClick={(e) => scrolling(e)}
                  >
                    Labeling Services
                  </a>
                </li>
                <li>
                  <a
                    href="#ai-solutions"
                    className="footer-link"
                    onClick={(e) => scrolling(e)}
                  >
                    Ai Solutions
                  </a>
                </li>
              </ul>
            </div>
          )}

          <div className="div-block-18">
            <h2 className="footer-heading">Be in Contact</h2>
            <ul className="w-list-unstyled">
              <li>
                <a
                  href="https://goo.gl/maps/ecwDpASDoXE1RyCd8"
                  target="_blank"
                  className="footer-link"
                  rel="noreferrer"
                >
                  Suite 300, 1716-16 Ave NW, Calgary, Alberta T2M 0L7
                </a>
              </li>
              <li>
                <a href="tel:+1(403)919-7626" className="footer-link">
                  {" "}
                  +1 (403) 919-7626
                  <br />
                </a>
              </li>
              <li>
                <a href="mailto:sales@meti.ai" className="footer-link">
                  sales@meti.ai
                  <br />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-block-3">
          Copyright © 2010 - 2024 All rights reserved for Micro Engineering Tech
          Inc.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
