import rglogo22 from "../images/Logos/rglogo22-full-color-1.svg";
import client2 from "../images/Logos/logo2.png";
import php from "../images/Logos/BHP-Logo.png";
import university from "../images/Logos/university.jpg";
import armc from "../images/Logos/armc.png";
import gulogulo from "../images/Logos/golgol.svg";
import cansel from "../images/Logos/cansel.png";
import navinst from "../images/Logos/navinst.png";
import queens from "../images/Logos/QueensLogo_colour.png";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
} from "swiper/modules";
import { useGloblaContext } from "../context/AutoContext";
import { useEffect } from "react";

export default function Clinets() {
  const { handleSubmenu, numSlidClient, setNumSlidClient } = useGloblaContext();

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setNumSlidClient(2);
    } else {
      setNumSlidClient(3);
    }
  }, []);

  return (
    <section className="pd-section clients" onMouseOver={handleSubmenu}>
      <div className="clinets-container">
        <h2 className="heading-section">Our partners and clients</h2>
        <Swiper
          loop={true}
          cssMode={true}
          slidesPerView={numSlidClient}
          navigation={true}
          mousewheel={true}
          keyboard={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper"
        >
          <SwiperSlide className="d-flex align-items-center">
            <img src={rglogo22} alt="tst" className="img-19" />
          </SwiperSlide>
          <SwiperSlide className="d-flex">
            <img src={client2} alt="tst" className="img-13" />
          </SwiperSlide>
          <SwiperSlide className="d-flex">
            <img src={php} alt="tst" className="img-18" />
          </SwiperSlide>
          <SwiperSlide className="d-flex">
            <img src={queens} alt="tst" className="img-18" />
          </SwiperSlide>
          <SwiperSlide className="d-flex">
            <img src={university} alt="tst" className="img-18" />
          </SwiperSlide>
          <SwiperSlide className="d-flex">
            <img src={armc} alt="tst" className="img-9" />
          </SwiperSlide>
          <SwiperSlide className="d-flex">
            <img src={navinst} alt="tst" className="img-10" />
          </SwiperSlide>
          <SwiperSlide className="d-flex">
            <img src={gulogulo} alt="tst" className="img-18" />
          </SwiperSlide>
          <SwiperSlide className="d-flex">
            <img src={cansel} alt="tst" className="img-18" />
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
}
