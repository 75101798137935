import React, { useState, useContext } from "react";
import {sublinks} from "../data";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [page, setPage] = useState({ page: "", links: [] });
  const [location, setLocation] = useState({});
  const [numSlidClient, setNumSlidClient] = useState(null);
  const [numSlidAi, setNumSlidAi] = useState(null);

  window.onresize = () => {
    if (window.innerWidth <= 768) {
      setNumSlidClient(2);
    } else {
      setNumSlidClient(3);
    }
    if (window.innerWidth <= 992) {
      setNumSlidAi(2);
    } else {
      setNumSlidAi(3);
    }
  };

  const scrolling = (e, func) => {
    e.preventDefault();
    const id = e.currentTarget.getAttribute("href").slice(1);
    const nav = document.getElementById("nav");
    const navHeight = nav.getBoundingClientRect().height;
    const section = document.getElementById(id);
    const postion = section.offsetTop - navHeight; // 61 = heigth navbar
    window.scrollTo({
      left: 0,
      top: postion,
    });
    if (func) {
      func();
    }
  };

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleSubmenu = (e) => {
    // if (!e.target.classList.contains("scroll-link")) {
      // }
      closeSubmenu();
  };

  const openSubmenu = (text, coordinates) => {
    const page = sublinks.find((link) => link.page === text);
    setPage(page);
    setLocation(coordinates);
    setIsSubmenuOpen(true);
  };
  const closeSubmenu = () => {
    setIsSubmenuOpen(false);
  };

  return (
    <AppContext.Provider
      value={{
        isSidebarOpen,
        isSubmenuOpen,
        openSidebar,
        closeSidebar,
        openSubmenu,
        closeSubmenu,
        handleSubmenu,
        scrolling,
        setNumSlidClient,
        setNumSlidAi,
        numSlidClient,
        numSlidAi,
        page,
        location,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGloblaContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider, useGloblaContext };
