import React from "react";
import { Link } from "react-router-dom";
import NavAbout from "../components/NavAbout";

function PageNOtFound() {
  return (
    <>
      <NavAbout />
      <div className="not-found " style={{ height: "100vh" }}>
        <h2>
          Page Not Found! <span style={{ color: "red" }}>404</span>
        </h2>
        <p>
          Go To
          <Link to="/" className="home-link">
            {" "}
            Home Page
          </Link>
        </p>
      </div>
    </>
  );
}

export default PageNOtFound;
