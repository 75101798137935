import React from "react";

function VisionAndMission() {
  return (
    <section className="about-container pd-section">
      <div className="Vision-Mission">
        <h2>Vision And Mission</h2>
        <p>
          <strong>Our vision:</strong> is to revolutionize the business world by
          empowering organizations globally with state-of-the-art AI technology.
          We focus on being the catalyst for unlocking unparalleled insights and
          impacts, paving the way for a future where businesses are profoundly
          intelligent and adept in harnessing the power of data.
        </p>
        <p>
          <strong>Our mission</strong> is to deliver a comprehensive suite of
          data-driven AI services and solutions, spanning from meticulous data
          annotation to sophisticated model design and seamless deployment. We
          are dedicated to setting new standards in innovation, reliability,
          security, and ethical data practices. Our goal is to forge enduring
          partnerships with businesses across diverse data-centric industries,
          providing them with the tools and expertise to thrive in an
          increasingly data-driven world.
        </p>
      </div>
      <div className="Our-Journey">
        <h2>Our Journey</h2>
        <p>
          Founded in 2010, Micro Engineering Tech Inc. (METI) has established
          itself as a forward-looking and innovative company. Born from a vision
          to see opportunities in challenges, METI.ai has grown in size,
          capability, and impact. Driven by a commitment to excellence and a
          belief in the power of technology to make a difference.
        </p>
      </div>
      <div className="Our-Values">
        <h2>Our Values</h2>
        <p>
          <strong>Agility:</strong> METI.ai values adaptability and
          responsiveness. This means the company is quick to respond to market
          changes, customer needs, and technological advancements. Agility at
          METI.ai involves flexible strategies, a dynamic workforce, and a
          culture that embraces change as an opportunity for growth.
        </p>
        <p>
          <strong>Integrity:</strong> For METI, integrity signifies honesty and
          ethical behavior in all its dealings. This value encompasses
          transparent communication, accountability for actions, and a
          commitment to doing what is right, not just what is profitable or
          easy.
        </p>
        <p>
          <strong>Quality:</strong> METI.ai is dedicated to excellence in its
          products, services, and processes. Quality for METI.ai means meeting
          and exceeding customer expectations, continual improvement, and
          attention to detail. This involves rigorous testing, meticulous
          design, and a commitment to using the best materials and methodologies
          available.
        </p>
        <p>
          <strong>Innovation:</strong> Innovation at METI.ai is about fostering
          creativity and forward-thinking. It’s not just about creating new
          products or services, but also about finding novel solutions to
          problems, improving existing processes, and staying ahead of the curve
          in technology and industry trends.
        </p>
        <p>
          <strong>Diversity:</strong> Diversity for METI.ai encompasses
          embracing a wide range of backgrounds, experiences, and perspectives.
          This value is about more than just demographic diversity; it also
          includes diversity of thought, skills, and approaches.
        </p>
        <p>
          <strong>Inclusion:</strong> Inclusion at METI.ai means creating an
          environment where everyone feels valued, heard, and involved. It’s
          about ensuring equal opportunities for all employees, fostering a
          sense of belonging, and actively seeking input from diverse groups.
        </p>
      </div>

      <div className="Our-Impact">
        <h2>Our Impact</h2>
        <p>
          Enabling smarter, more intuitive AI solutions across industries.
          Commitment to quality and accuracy in data labeling. Positioning as a
          key enabler in the journey towards intelligent technology solutions.
        </p>
      </div>
    </section>
  );
}

export default VisionAndMission;
