import React, { useState } from "react";
import { FaAnglesUp } from "react-icons/fa6";

function ButtonTop() {
  const [top, setTop] = useState("");
  window.onscroll = () => {
    if (window.scrollY > 400) {
      setTop("up-btn");
    } else {
      setTop("");
    }
  };

  const buttonToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <div className={`btn-scroll ${top}`} onClick={buttonToTop}>
      <FaAnglesUp />
    </div>
  );
}

export default ButtonTop;
