// Use Cases Section
import usecase1 from "./images/usecase1.png";

// aI Solutions Section 
import customized from "./images/AI solutions icons/customized_icon.png";
import feasibility from "./images/AI solutions icons/feasibility_icon.png";
import ideation from "./images/AI solutions icons/ideation_icon.png";
import dataCollection from "./images/AI solutions icons/data_collection_icon.png";
import dataLabeling from "./images/AI solutions icons/data_labeling_icon.png";
import aiModelDesign from "./images/AI solutions icons/ai_model_design_icon.png";
import aiModelDev from "./images/AI solutions icons/ai_model_dev_icon.png";
import aiModelTraining from "./images/AI solutions icons/ai_model_training_icon.png";
import aiModelTesting from "./images/AI solutions icons/ai_model_testing_icon.png";
import aiModelDeployment from "./images/AI solutions icons/ai_model_deployment_icon.png";
import devDeployment from "./images/AI solutions icons/solution_dev_deployment_icon.png";
import feedback from "./images/AI solutions icons/feedback_icon.png";

// IndustriesData section
import industries1 from "./images/industries1.png";
import industries2 from "./images/industries2.jpg";
import industries3 from "./images/industries3.png";
import industries4 from "./images/industries4.png";
import industries5 from "./images/industries5.png";

// ai section
import govermentAi from "./images/govermentAi.jpg";


export const sublinks = [
  {
    page: "Services",
    links: [
      { label: "Services Packages", url: "#services-packages" },
      { label: "Labeling Services", url: "#labeling-services" },
      { label: "AI Solutions", url: "#ai-solutions" },
    ],
  },
];

export const UseCasesSection = [
  {
    header: "Automotive AI data labeling",
    text: "Calgary Downtown 2D and 3D sensor dataset ​LiDAR + 2D maps with features​",
    image: usecase1,
  },
  {
    header: "Geospatial mapping",
    text: "Geospatial mapping - Calgary Downtown mapping dataset (LiDAR + 2D maps with infrastructure features)",
    image: industries3,
  },
  {
    header: "Mining and material analysis",
    text: "Mining and material analysis – soil & material AI classification dataset (2D Image labeling)​",
    image: industries4,
  },
  {
    header: "Pavement distress monitoring",
    text: "Pavement distress monitoring - Calgary downtown dataset (2D Image labeleing)​",
    image: industries2,
  },
  {
    header: "Government AI",
    text: "Government AI – Calgary Road monitoring and traffic analysis dataset (2D Image labeling)​",
    image: govermentAi,
  },
];

export const aISolutionsData = [
  {
    header: "Ideation and Objectives",
    button: "Ideation and Objectives",
    img: ideation,
    text: "At first, the team collaborates with stakeholders to define the vision, pinpointing problems to solve or processes to enhance with AI. This phase involves setting clear objectives and success metrics for the project.",
  },
  {
    header: "Feasibility Analysis",
    button: "Feasibility Analysis",
    img: feasibility,
    text: "METI.ai experts conduct a thorough analysis to assess the technical and economic viability of the proposed AI project. They evaluate available data, required resources, potential ROI, and technical constraints.​",
  },
  {
    header: "Customized Solution Proposal",
    button: "Customized Solution Proposal",
    img: customized,
    text: "Based on the feasibility study, a tailored proposal is crafted detailing the AI solution architecture, the technologies to be used, the project timeline, and the expected outcomes.​",
  },
  {
    header: "Data Catalogue Design",
    button: "Data Catalogue Design",
    img: ideation,
    text: "A data catalogue is designed to organize and manage the datasets that will be used for training the AI models. This catalogue defines the data types, sources, and structures, ensuring that data is easily accessible and well-documented.​",
  },
  {
    header: "Data Collection Consultation",
    button: "Data Collection Consultation",
    img: dataCollection,

    text: "This step involves advising on the best practices for data collection, ensuring data quality, and compliance with relevant regulations. This may also include setting up data pipelines for continuous data ingestion. Actual data collection could be provided in some cases.​",
  },
  {
    header: "Data Labeling and Annotation",
    button: "Data Labeling and Annotation",
    img: dataLabeling,

    text: "High-quality data labeling and annotation are critical for AI learning. This phase ensures that the data used to train the models is accurately tagged. In this step, METI.ai team provides the labeled data through its “Quality on Time (QoT)” strategy.​",
  },
  {
    header: "AI Model Design",
    button: "AI Model Design",
    img: aiModelDesign,

    text: "The conceptual design of the AI model is created, defining the model's structure, the algorithms to be used, and how the model will process and learn from data.​",
  },
  {
    header: "AI Model Development",
    button: "AI Model Development",
    img: aiModelDev,

    text: "Developers write the code and develop the AI model based on the design specifications. This includes setting up the neural network layers, activation functions, and learning processes.​",
  },
  {
    header: "AI Model Training",
    button: "AI Model Training",
    img: aiModelTraining,

    text: "The model is trained using the prepared datasets. This involves adjusting model parameters, optimizing performance, and ensuring the model accurately predicts or classifies according to the defined objectives.​",
  },
  {
    header: "AI Model Testing",
    button: "AI Model Testing",
    img: aiModelTesting,

    text: "After training, the model undergoes rigorous testing to evaluate its performance on unseen data. This step is crucial to ensure that the model generalizes well and to identify any areas for improvement.​",
  },
  {
    header: "AI Model Deployment",
    button: "AI Model Deployment",
    img: aiModelDeployment,

    text: "The trained model is deployed into a production environment where it can begin processing real-world data. This could be on-premises, in the cloud, or at the edge, depending on the application.​",
  },
  {
    header: "Development and Deployment for the final solution software",
    button: "Development and Deployment",
    img: devDeployment,
    text: "Development and Deployment for the final solution software For AI-driven outcome delivery, a user-friendly application is developed and deployed. This could be a cloud-based platform or a desktop app, designed to allow users to interact with the AI model effectively.​",
  },
  {
    header: "Feedback and Enhancement Cycle",
    button: "Feedback and Enhancement",
    img: feedback,

    text: "Feedback and Enhancement Cycle Post-deployment, user feedback is collected and the model's performance is continuously monitored. Based on this feedback and ongoing performance data, the model may be fine-tuned and enhanced to adapt to new data, user needs, or changes in the operating environment.​",
  },
];

export const IndustriesData = [
  {
    header: "Autonomous Vehicles",
    direction: "right",
    industriesButtons: [
      "Pioneering the Future of Mobility",
      "​Enhancing AI Perception",
      "Collaborating with Industry Leaders",
    ],
    values: [
      "At Micro Engineering Tech Inc.(METI.ai), we are at the cutting edge of revolutionizing autonomousvehicle technology. Our expertise in labeling 2D and 3D sensor datais pivotal for the development of self-driving cars.​",
      "The success of autonomous vehicles heavily relies on their ability to perceive and understand the road and its hazards. METI.ai's data labeling services are integral in training AI models to recognize and react to a myriad of scenarios, from pedestrian crossings to unpredictable weather conditions.",
      "METI.ai collaborates with leading automotive manufacturers and tech companies, bringing our specialized skills to the forefront of autonomous vehicle development. ​",
    ],
    image: industries1,
    images: [],

    infoImage:
      "Autonomous vehicle AI models using METI.ai labeling​Calgary 2022",
  },
  {
    header: "Road Safety and pavement distress monitoring​",
    direction: "left",
    industriesButtons: [
      "Advancing Road Safety and Maintenance",
      "Collaborating for Smarter Infrastructure",
    ],
    values: [
      "​METI.ai is at the forefront of improving pavement distress and road condition monitoring through our sophisticated data labeling services. By accurately annotating 2D and 3D sensor data, we enable the development of advanced AI systems that can detect and assess various forms of pavement distress, such as cracks, potholes, and surface wear. This detailed analysis is crucial for timely maintenance and repair, ensuring road safety and prolonging pavement life.​",
      "​METI.ai partners with government bodies, infrastructure management companies, and technology firms to enhance road monitoring systems. Our data labeling contributes to the development of smart city initiatives, where real-time monitoring of road conditions plays a vital role in urban planning and public safety. By aiding in the accurate assessment of pavement health, METI.ai is helping pave the way for more resilient and sustainable road infrastructures.​",
    ],
    image: industries2,
    images: [],
  },
  {
    header: "Geospatial Mapping​",
    direction: "right",
    industriesButtons: [
      "Revolutionizing Map Making",
      "Empowering Precision and Detail",
      "Collaboration with Industry Experts",
    ],
    values: [
      "In the realm of geospatial mapping, METI.ai is a game-changer. Our advanced data labeling techniques transform 2D and 3D sensor data into detailed, accurate maps. We help in creating comprehensive, high-resolution maps that are vital for various applications.",
      "METI.ai’s meticulous approach to data annotation ensures that every contour, elevation, and structure is accurately captured and represented.",
      "Our partnerships with geospatial experts and organizations enable us to stay at the forefront of mapping technology. We are proud to contribute to projects that require the highest level of accuracy and detail.",
    ],
    image: industries3,
    images: [],
    infoImage: "Calgary Downtown Geospatial HD Map by METI​.ai",
  },
  {
    header: "Mining and Material analysis​",
    direction: "left",
    industriesButtons: [
      "Transforming the Mining Industry",
      "Enabling Smart Mining Operations",
      "Partnership with Mining Leaders: ",
    ],
    values: [
      "METI.ai is revolutionizing the mining industry with our advanced data labeling solutions. By accurately annotating 2D and 3D sensor data from mining equipment and environments, we facilitate the development of AI-driven tools that enhance operational efficiency and safety. ​",
      "Our expertise in processing complex sensor data is key to developing smart mining solutions. These AI-powered tools help in automating tasks, optimizing resource extraction, and reducing operational costs. ​",
      "METI.ai collaborates with leading companies in the mining sector, bringing our specialized data labeling capabilities to enhance their technological advancements. Our work plays a crucial role in the shift towards automated and intelligent mining systems.​",
    ],
    images: [industries4, industries5, industries5],
    infoImage: "Potash mine AI soil classification based on METI.ai labeling​",
  },
];