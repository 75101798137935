import React, { useState } from "react";
import { FaSquareCheck } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa6";
import { IoIosArrowUp } from "react-icons/io";
import { useGloblaContext } from "../context/AutoContext";

function ServicePackges() {
  const { handleSubmenu, scrolling } = useGloblaContext();
  const [showFeatures, setShowFeatures] = useState(false);
  const handelShowing = () => {
    setShowFeatures(!showFeatures);
  };
  return (
    <section
      className="pd-section"
      id="services-packages"
      onMouseOver={handleSubmenu}
    >
      <h2 className="heading-section">service packages</h2>
      <div className="container wrapper">
        <div className="tables basic">
          <div className="head_tab mb-4 ">
            <h4>
              On-demand Labeling
              <br /> Workforce package
            </h4>
          </div>

          <ul
            className={`features ${
              showFeatures ? "hidden-featuer" : "show-featuer"
            }`}
          >
            <li>
              <FaTimes className="icon cross" />
              <span className="list-name"> AI solution consultation</span>
            </li>
            <li>
              <FaTimes className="icon cross" />
              <span className="list-name">AI feasibility analysis</span>
            </li>
            <li>
              <FaTimes className="icon cross" />
              <span className="list-name"> Data curation</span>
            </li>
            {/* <div style={{ height: "0px", transition: "1s" }}> */}
            <li>
              <FaTimes className="icon cross" />
              <span className="list-name">Labeling workforce management </span>
            </li>
            <li>
              <FaTimes className="icon cross" />
              <span className="list-name"> AI-assisted labeling</span>
            </li>
            <li>
              <FaSquareCheck className="icon check" />
              <span className="list-name"> Data labeling</span>
            </li>
            <li>
              <FaTimes className="icon cross" />
              <span className="list-name">Data storage</span>
            </li>
            <li>
              <FaTimes className="icon cross" />
              <span className="list-name">AI model production</span>
            </li>
            <li>
              <FaTimes className="icon cross" />
              <span className="list-name">AI model training </span>
            </li>
            <li>
              <FaTimes className="icon cross" />
              <span className="list-name">AI Solution development</span>
            </li>
            {/* </div> */}
          </ul>

          <div className="show-features" onClick={handelShowing}>
            <h4>
              {showFeatures ? "See less features" : "See all features"}{" "}
              <span className="icon cross">
                {showFeatures ? <IoIosArrowUp /> : <FaAngleDown />}
              </span>
            </h4>
          </div>
          <a
            href="#labeling-services"
            className="learn-more-link"
            onClick={(e) => scrolling(e)}
          >
            <button className="learn-more">
              <span>Learn More</span>
            </button>
          </a>
        </div>

        <div className="tables ultimate">
          <div className="head_tab mb-4">
            <h4>
              End-to-end Labeling
              <br />
              Services package
            </h4>
          </div>
          <ul
            className={`features ${
              showFeatures ? "hidden-featuer" : "show-featuer"
            }`}
          >
            <li>
              <FaTimes className="icon cross" />
              <span className="list-name"> AI solution consultation</span>
            </li>
            <li>
              <FaTimes className="icon cross" />
              <span className="list-name">AI feasibility analysis</span>
            </li>
            <li>
              <FaTimes className="icon cross" />
              <span className="list-name"> Data curation</span>
            </li>
            <div style={{ height: "0px", transition: "1s" }}>
              <li>
                <FaSquareCheck className="icon check" />
                <span className="list-name">
                  Labeling workforce management{" "}
                </span>
              </li>
              <li>
                <FaSquareCheck className="icon check" />
                <span className="list-name"> AI-assisted labeling</span>
              </li>
              <li>
                <FaSquareCheck className="icon check" />
                <span className="list-name"> Data labeling</span>
              </li>
              <li>
                <FaSquareCheck className="icon check" />
                <span className="list-name">Data storage</span>
              </li>
              <li>
                <FaTimes className="icon cross" />
                <span className="list-name">AI model production</span>
              </li>
              <li>
                <FaTimes className="icon cross" />
                <span className="list-name">AI model training </span>
              </li>
              <li>
                <FaTimes className="icon cross" />
                <span className="list-name">AI Solution development</span>
              </li>
            </div>
          </ul>
          <div className="show-features" onClick={handelShowing}>
            <h4>
              {showFeatures ? "See less features" : "See all features"}{" "}
              <span className="icon cross">
                {showFeatures ? <IoIosArrowUp /> : <FaAngleDown />}
              </span>
            </h4>
          </div>
          <a
            href="#ai-solutions"
            className="learn-more-link"
            onClick={(e) => scrolling(e)}
          >
            <button className="learn-more">
              <span> Learn More</span>
            </button>
          </a>
        </div>

        <div className="tables premium ">
          <div className="head_tab mb-4">
            <h4>
              Turn-key AI solutions
              <br />
              package
            </h4>
          </div>
          <ul
            className={`features ${
              showFeatures ? "hidden-featuer" : "show-featuer"
            }`}
          >
            <li>
              <FaSquareCheck className="icon check" />
              <span className="list-name"> AI solution consultation</span>
            </li>
            <li>
              <FaSquareCheck className="icon check" />
              <span className="list-name">AI feasibility analysis</span>
            </li>
            <li>
              <FaSquareCheck className="icon check" />
              <span className="list-name"> Data curation</span>
            </li>
            <div style={{ height: "0px", transition: "1s" }}>
              <li>
                <FaSquareCheck className="icon check" />
                <span className="list-name">
                  Labeling workforce management{" "}
                </span>
              </li>
              <li>
                <FaSquareCheck className="icon check" />
                <span className="list-name"> AI-assisted labeling</span>
              </li>
              <li>
                <FaSquareCheck className="icon check" />
                <span className="list-name"> Data labeling</span>
              </li>
              <li>
                <FaSquareCheck className="icon check" />
                <span className="list-name">Data storage</span>
              </li>
              <li>
                <FaSquareCheck className="icon check" />
                <span className="list-name">AI model production</span>
              </li>
              <li>
                <FaSquareCheck className="icon check" />
                <span className="list-name">AI model training </span>
              </li>
              <li>
                <FaSquareCheck className="icon check" />
                <span className="list-name">AI Solution development</span>
              </li>
            </div>
          </ul>
          <div className="show-features" onClick={handelShowing}>
            <h4>
              {showFeatures ? "See less features" : "See all features"}{" "}
              <span className="icon cross">
                {showFeatures ? <IoIosArrowUp /> : <FaAngleDown />}
              </span>
            </h4>
          </div>
          <a
            href="#ai-solutions"
            className="learn-more-link"
            onClick={(e) => scrolling(e)}
          >
            <button className="learn-more">
              <span> Learn More</span>
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}

export default ServicePackges;
