import React, { useEffect, useState } from "react";
import { useGloblaContext } from "../context/AutoContext";
import digram from "../images/digram2.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { aISolutionsData } from "../data";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
function AISolutions() {
  const [toggle, setToggle] = useState(0);
  // const [numSlid, setNumSlid] = useState(3);
  const { handleSubmenu, numSlidAi, setNumSlidAi } = useGloblaContext();
  const handleToggle = (e, index) => {
    setToggle(index);
  };

  useEffect(() => {
    if (window.innerWidth <= 992) {
      setNumSlidAi(2);
    } else {
      setNumSlidAi(3);
    }
  }, []);

  return (
    <section
      className="pd-section ai-solutions"
      id="ai-solutions"
      onMouseOver={handleSubmenu}
    >
      <div className="header-ai">
        <h2 className="heading-section">AI Solutions</h2>
        <p>
          Each step is interconnected and iterative, with frequent evaluations
          to ensure that the AI solution remains aligned with the business
          objectives and delivers the intended value.​
        </p>
      </div>
      <div className="ai-right">
        <img src={digram} alt="digram for Ai" />
      </div>
      <div className="ai-left">
        <Swiper
          loop={true}
          cssMode={true}
          slidesPerView={numSlidAi}
          navigation={true}
          mousewheel={true}
          keyboard={true}
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper"
        >
          {aISolutionsData.map((item, index) => (
            <SwiperSlide key={index}>
              <div
                className="content-swiper"
                onClick={(e) => handleToggle(e, index)}
              >
                <img className="icon-ai" src={item.img} alt="ionc" />
                <button>{item.button}</button>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <h3>{aISolutionsData[toggle].header}​</h3>
        <p>{aISolutionsData[toggle].text}</p>
      </div>
    </section>
  );
}

export default AISolutions;