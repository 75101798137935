import React from "react";
import Header from "../components/Header";
import VisionAndMission from "../components/VisionAndMission";
import OurExpertise from "../components/OurExpertise";
import Footer from "../components/Footer";
import NavAbout from "../components/NavAbout";
function AboutUS() {
  return (
    <>
      <NavAbout />
      <Header aboutUs="aboutUs" />
      <VisionAndMission />
      <OurExpertise />
      <Footer aboutUs="aboutUs" />
    </>
  );
}

export default AboutUS;
