import React from "react";
import { useGloblaContext } from "../context/AutoContext";
import {UseCasesSection} from "../data";
function UseCases() {
  const { handleSubmenu } = useGloblaContext();

  return (
    <section className="pd-section" id="use-cases​" onMouseOver={handleSubmenu}>
      <div className="container">
        <h2 className="heading-section">Use Cases</h2>
        <div className="grid-services">
          {UseCasesSection.map((item) => (
            <div className="box" key={item.header}>
              <img src={item.image} alt="useCases" />
              <div className="contents">
                <h5>{item.header}</h5>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default UseCases;
