import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useGloblaContext } from "../context/AutoContext";

function ContactUs() {
  const { handleSubmenu } = useGloblaContext();

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_lcskw15",
        "template_s4b9bv4",
        form.current,
        "XpSCzDxKAI7f2eA3F"
      )
      .then(
        (result) => {
          alert("Thank you! Your request has been received!");
          // console.log(result.text);
        },
        (error) => {
          // console.log(error.text);
          alert("Oops! Something went wrong while submitting your message.");
        }
      );
    e.target.reset();
  };

  return (
    <section
      className="contact-form-2 pd-section"
      id="contact-us​"
      onMouseOver={handleSubmenu}
    >
      <div className="container-4 w-container">
        <div className="div-block-21">
          <div className="div-block-23">
            <h2 className="heading-5">
              Connect with the Experts in AI solutions and Data Labeling
            </h2>
            <div id="formInstructions" className="small-text">
              Ready to take your business to the next level of AI-driven future?
              Reach out to METI.ai and leverage our vast experience in AI
              solution deployment and data labeling to achieve groundbreaking
              results. Your journey towards innovation starts here!
            </div>
            <div className="div-block-29"></div>
          </div>
          <div className="form-block w-form">
            <form ref={form} onSubmit={sendEmail}>
              <div id="formInstructions" className="small-text">
                Request a demo today
              </div>
              <div className="contact-form-grid">
                <div id="w-node-d38e752b3055-752b3046" className="div-block-24">
                  <label
                    for="First-Name-2"
                    id="contact-first-name"
                    className="field-label"
                  >
                    First name *
                  </label>
                  <input
                    type="text"
                    className="text-field w-input"
                    maxlength="256"
                    name="first-name"
                    id="firstName"
                    // data-name="First Name 2"
                    required
                  />
                </div>
                <div id="w-node-d38e752b3059-752b3046" className="div-block-25">
                  <label
                    for="Last-Name-2"
                    id="contact-last-name"
                    className="field-label-2"
                  >
                    Last name *
                  </label>
                  <input
                    type="text"
                    className="text-field w-input"
                    maxlength="256"
                    name="last-name"
                    id="lastName"
                    // data-name="Last Name 2"
                    required
                  />
                </div>
                <div id="w-node-d38e752b305d-752b3046" className="div-block-26">
                  <label
                    for="Email-2"
                    id="contact-email"
                    className="field-label-3"
                  >
                    Company email *
                  </label>
                  <input
                    type="email"
                    className="text-field w-input"
                    maxlength="256"
                    name="email"
                    id="email"
                    // data-name="Email 2"
                    required
                  />
                </div>
                <div id="w-node-d38e752b3061-752b3046" className="div-block-27">
                  <label
                    for="Contact-Phone-Number-2"
                    id="contact-phone"
                    className="field-label-4"
                  >
                    Phone number
                  </label>
                  <input
                    type="tel"
                    className="text-field w-input"
                    maxlength="256"
                    name="contact-phone-number"
                    id="contact-phone-number"
                    required
                    // data-name="Contact Phone Number 2"
                  />
                </div>
                <div id="w-node-d38e752b3065-752b3046" className="div-block-28">
                  <label
                    for="Message-2"
                    id="contact-message"
                    className="field-label-5"
                  >
                    Message
                  </label>
                  <textarea
                    style={{height: "100px"}}
                    maxlength="5000"
                    name="message"
                    className="text-field w-input"
                    id="message"
                    // data-name="Message 2"
                    required
                  ></textarea>
                </div>
              </div>

              <input
                type="submit"
                value="Submit"
                data-wait="Please wait..."
                className="submit-button-2 animgradient w-button"
              />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
