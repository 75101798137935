import React from "react";
import headerHome from "../images/background of header.jpg";
import headerAbout from "../images/background-AboutUs2.jpg";
import { useGloblaContext } from "../context/AutoContext";

function Header({ aboutUs }) {
  const { handleSubmenu, scrolling } = useGloblaContext();
  return (
    <header id="home" onMouseOver={handleSubmenu}>
      <img
        src={aboutUs ? headerAbout : headerHome}
        alt="Header background"
        className="imgs"
      />
      <div className="text">
        <div className="content">
          <h1 className={aboutUs ? "about-us" : "home"}>
            {aboutUs ? (
              <>
                WE ARE...METI.
                <span style={{ textTransform: "lowercase" }}>ai</span>
              </>
            ) : (
              "Transform Your Data Elevate Your AI"
            )}
          </h1>
          <h3 className="mb-0">
            {aboutUs
              ? "METI.ai combines the technologies, processes and people that, together, deliver the data enrichment and annotations companies rely on. Learn more about METI.ai"
              : "​​Expert Data Annotators & AI Solution Providers for Smart Organizations"}
            ​
          </h3>
          {!aboutUs && (
            <>
              <p className="m-0 p-0">
                Our promise is{" "}
                <i>
                  <q>Quality on Time</q>
                </i>
              </p>
              <button className="learn-more d-inline-block mt-3">
                <a
                  href="#contact-us​"
                  className=""
                  onClick={(e) => scrolling(e)}
                >
                  <span>Talk to an expert</span>
                </a>
              </button>
            </>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
