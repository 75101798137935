import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useGloblaContext } from "../context/AutoContext";
import metiWhite2 from "../images/Logos/meti2.png";
import metiblack from "../images/Logos/metiblack.png";
import { FaAnglesUp } from "react-icons/fa6";

function Navbar() {
  const [top, setTop] = useState("");
  const { openSidebar, openSubmenu, closeSubmenu, scrolling } =
    useGloblaContext();
  const [navBackground, setNavBackground] = useState("");
  const [toggle, toggleAvtive] = useState(0);
  const toggleTab = (index) => {
    toggleAvtive(0);
  };

  const displaySubmenu = (e) => {
    const page = e.target.textContent;
    const tempBtn = e.target.getBoundingClientRect();
    const center = (tempBtn.left + tempBtn.right) / 2;
    const bottom = tempBtn.bottom - 3;
    openSubmenu(page, { center, bottom });
  };

  const handleSubmenu = (e) => {
    if (e.target.classList.contains("scroll-link")) {
      closeSubmenu();
    } else if (e.target.classList.contains("nav-center")) {
      closeSubmenu();
    }
  };

  const buttonToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  useEffect(() => {
    buttonToTop();
  }, []);
  window.onscroll = () => {
    if (window.scrollY > 68) {
      setNavBackground("fixed-nav");
    } else {
      setNavBackground("");
    }
    if (window.scrollY > 400) {
      setTop("up-btn");
    } else {
      setTop("");
    }
  };

  const scrollAndToggle = (e, index) => {
    scrolling(e);
    toggleTab(index);
  };
  return (
    <nav className={navBackground} id="nav" onMouseOver={handleSubmenu}>
      <div className="container">
        <div className="nav-center">
          <div className="nav-header">
            <a href="#home">
              <img
                src={metiWhite2}
                alt="meti White logo"
                className="meti-logo-white"
              />
              <img
                src={metiblack}
                alt="meti White logo"
                className="meti-logo-black"
              />
            </a>
            <button className="nav-toggle" onClick={openSidebar}>
              <FaBars />
            </button>
          </div>
          <div className="nav-links">
            <ul className="links">
              <li>
                <button
                  className={
                    toggle === 1 ? "service-link active" : "service-link"
                  }
                  onMouseOver={displaySubmenu}
                >
                  Services
                </button>
              </li>
              <li>
                <a
                  href="#industries"
                  className={
                    toggle === 1 ? "scroll-link active" : "scroll-link"
                  }
                  onClick={(e) => scrollAndToggle(e, 1)}
                >
                  Industries
                </a>
              </li>
              <li>
                <a
                  href="#use-cases​"
                  className={
                    toggle === 2 ? "scroll-link active" : "scroll-link"
                  }
                  onClick={(e) => scrollAndToggle(e, 2)}
                >
                  Use Cases​
                </a>
              </li>
              <li>
                <a
                  href="#contact-us​"
                  className={
                    toggle === 3 ? "scroll-link active" : "scroll-link"
                  }
                  onClick={(e) => scrollAndToggle(e, 3)}
                >
                  Contact Us
                </a>
              </li>
              <li>
                <Link to="about-us" className="scroll-link">
                  About us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`btn-scroll ${top}`} onClick={buttonToTop}>
        <FaAnglesUp />
      </div>
    </nav>
  );
}

export default Navbar;
