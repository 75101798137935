import React, { useState } from "react";

function IndustriesCard({ data }) {
  const [toggle, setToggle] = useState(0);
  const {
    header,
    direction,
    industriesButtons,
    values,
    image,
    images,
    infoImage,
  } = data;
  const handleButton = (e, index) => {
    setToggle(index);
  };
  return (
    <div className="container industries-container">
      {direction === "left" && (
        <div className="right-container">
          <figure className={direction}>
            {header === "Mining and Material analysis​" ? (
              <img src={images[toggle]} alt="industries1" />
            ) : (
              <img src={image} alt="industries1" />
            )}
            <figcaption>{infoImage && infoImage}</figcaption>
          </figure>
        </div>
      )}
      <div className="left-container">
        <h3>{header}​</h3>
        <figure className={direction}>
          {header === "Mining and Material analysis​" ? (
            <img src={images[toggle]} alt="industries1" />
          ) : (
            <img src={image} alt="industries1" />
          )}
          <figcaption>{infoImage && infoImage}</figcaption>
        </figure>
        <div className="scroll-buttons">
          <div className="industries-features">
            {industriesButtons.map((link, index) => (
              <>
                <button
                  className={`${toggle === index ? "active" : ""}`}
                  onClick={(e) => handleButton(e, index)}
                  key={link}
                >
                  {link}
                </button>
              </>
            ))}
          </div>
        </div>
        <p>{values[toggle]}</p>
      </div>
      {direction === "right" && (
        <div className="right-container">
          <figure className={direction}>
            <img src={image} alt="industries1" />
            <figcaption>{infoImage && infoImage}</figcaption>
          </figure>
        </div>
      )}
    </div>
  );
}

export default IndustriesCard;
