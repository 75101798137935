import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import metiWhite2 from "../images/Logos/meti2.png";
import metiblack from "../images/Logos/metiblack.png";
import { FaAnglesUp } from "react-icons/fa6";

function NavAbout() {
  const [top, setTop] = useState("");

  const [navBackground, setNavBackground] = useState("");
  const [toggle, toggleAvtive] = useState(0);
  // const toggleTab = (index) => {
  //   toggleAvtive(0);
  // };

  const buttonToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  useEffect(() => {
    buttonToTop();
  }, []);
  window.onscroll = () => {
    if (window.scrollY > 68) {
      setNavBackground("fixed-nav");
    } else {
      setNavBackground("");
    }
    if (window.scrollY > 400) {
      setTop("up-btn");
    } else {
      setTop("");
    }
  };

  return (
    <nav className={navBackground} id="nav">
      <div className="container">
        <div className="nav-header links">
          <Link to="/">
            <img
              src={metiWhite2}
              alt="meti White logo"
              className="meti-logo-white"
            />
            <img
              src={metiblack}
              alt="meti White logo"
              className="meti-logo-black"
            />
          </Link>
          <Link
            to="/"
            className={toggle === 1 ? "scroll-link active" : "scroll-link"}
          >
            Home
          </Link>
        </div>
      </div>
      <div className={`btn-scroll ${top}`} onClick={buttonToTop}>
        <FaAnglesUp />
      </div>
    </nav>
  );
}

export default NavAbout;
