import { FaTimes } from "react-icons/fa";
import {sublinks} from "../data";
import { useGloblaContext } from "../context/AutoContext";
import { Link } from "react-router-dom";

function Sidebar() {
  const { isSidebarOpen, closeSidebar, scrolling } = useGloblaContext();

  return (
    <aside className={isSidebarOpen ? "sidebar show-sidebar" : "sidebar"}>
      <div className="sidebar-container">
        <button className="close-btn" onClick={closeSidebar}>
          <FaTimes />
        </button>
        <ul className="sidebar-links">
          {sublinks.map((link) => {
            const { page, links } = link;
            return (
              <article key={page}>
                <h4>{page}</h4>
                <div className="sidebar-sublinks">
                  {links.map((el, index) => {
                    const { label, icon, url } = el;
                    return (
                      <a
                        href={url}
                        key={index}
                        onClick={(e) => scrolling(e, closeSidebar)}
                      >
                        {icon}
                        {label}
                      </a>
                    );
                  })}
                </div>
              </article>
            );
          })}
          <li>
            <a
              href="#industries"
              className="sidebar-link"
              onClick={(e) => scrolling(e, closeSidebar)}
            >
              industries
            </a>
          </li>
          <li>
            <a
              href="#use-cases​"
              className="sidebar-link"
              onClick={(e) => scrolling(e, closeSidebar)}
            >
              Use Case
            </a>
          </li>
          <li>
            <a
              href="#contact-us​"
              className="sidebar-link"
              onClick={(e) => scrolling(e, closeSidebar)}
            >
              Contact Us
            </a>
          </li>
          <li>
            <Link to="about-uS" className="sidebar-link" onClick={closeSidebar}>
              About US
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  );
}

export default Sidebar;
