import React from "react";
import { useGloblaContext } from "../context/AutoContext";
import IndustriesCard from "./IndustriesCard";
import {IndustriesData} from "../data";

function Industries() {
  const { handleSubmenu } = useGloblaContext();


  return (
    <section className="pd-section" id="industries" onMouseOver={handleSubmenu}>
      <h2 className="heading-section">Industries</h2>
      {IndustriesData.map((industr) => (
        <IndustriesCard data={industr} />
      ))}
    </section>
  );
}

export default Industries;
// Mining and Material analysis