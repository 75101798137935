import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { useGloblaContext } from "../context/AutoContext";

function MetiNumbers() {
  const [isCount, setIsCount] = useState(false);
  const { handleSubmenu } = useGloblaContext();

  return (
    <ScrollTrigger
      onEnter={() => setIsCount(true)}
      onExit={() => setIsCount(false)}
      className="bg-white pd-section"
    >
      <div
        onMouseOver={handleSubmenu}
        className="container text-center numbers-container"
      >
        <div className="numbers-box">
          {isCount && (
            <CountUp
              start={0}
              end={3}
              duration={3}
              delay={0}
              style={{ margin: "0", lineHeight: "1em" }}
              className="nums2"
            />
          )}
          B+
          <p>Objects labeled</p>
        </div>
        <div className="numbers-box">
          {isCount && (
            <CountUp
              start={0}
              end={500}
              duration={2}
              delay={0}
              style={{ margin: "0", lineHeight: "1em" }}
              className="nums1"
            />
          )}
          M+
          <p>Image Labels</p>
        </div>
        <div className="numbers-box">
          {isCount && (
            <CountUp
              start={0}
              end={3}
              duration={3}
              delay={0}
              style={{ margin: "0", lineHeight: "1em" }}
              className="nums2"
            />
          )}
          M+
          <p>Labels/h</p>
        </div>

        <div className="numbers-box">
          {isCount && (
            <CountUp
              start={0}
              end={3}
              duration={3}
              delay={0}
              style={{ margin: "0", lineHeight: "1em" }}
              className="nums2"
            />
          )}
          K+
          <p>Workforce</p>
        </div>
        <div className="numbers-box">
          {isCount && (
            <CountUp
              start={0}
              end={24}
              duration={3}
              delay={0}
              style={{ margin: "0", lineHeight: "1em" }}
              className="nums2"
            />
          )}
          /7
          <p>Operation</p>
        </div>
        <div className="numbers-box">
          {isCount && (
            <CountUp
              start={0}
              end={100}
              duration={2}
              delay={0}
              style={{ margin: "0", lineHeight: "1em" }}
              className="nums1"
            />
          )}
          K+
          <p>QA cycles/month</p>
        </div>
      </div>
    </ScrollTrigger>
  );
}

export default MetiNumbers;
