import React from "react";
import LabelingService from "../images/labelingServices.png";
import customDataLabeling from "../images/customData.jpg";
import VIDEOLABELING from "../images/VIDEO LABELING.jpg";
import LABELING from "../images/WhatsApp Image 2024-01-03 at 22.21.24_bdf7cb51.jpg";
import { useGloblaContext } from "../context/AutoContext";

function LabelingServices() {
  const { handleSubmenu } = useGloblaContext();

  return (
    <section
      className="pd-section"
      id="labeling-services"
      onMouseOver={handleSubmenu}
    >
      <div className="container">
        <h2 className="heading-section">Labeling Services</h2>
        <div className="grid-services">
          <div className="box">
            <img src={LabelingService} alt="LabelingService" />
            <div className="contents">
              <h5>Image Labeling</h5>
              <p>
                Precision Bounding Boxes: Accurately identify and encapsulate
                objects in 2D images. Semantic Segmentation: Distinguish between
                various entities by classifying every pixel. Landmark
                Annotation: Pinpoint specific points of interest on objects or
                figures. Scene Categorization: Understand and label entire
                scenes or backgrounds.
              </p>
            </div>
          </div>
          <div className="box">
            <img src={VIDEOLABELING} alt="LabelingService" />
            <div className="contents">
              <h5>Video Labeling</h5>
              <p>
                Frame-by-frame Annotation: Detailed labeling for every single
                frame in a video sequence. Temporal Analysis: Track objects and
                entities over time, understanding their motion and trajectory.
                Scene Transition Recognition: Identify and categorize shifts in
                scenes or contexts within a video.
              </p>
            </div>
          </div>
          <div className="box">
            <img src={LABELING} alt="LabelingService" />
            <div className="contents">
              <h5>LiDAR Labeling</h5>
              <p>
                Multi-sensor 3D LiDAR Labeling Dive deep into three-dimensional
                data. 3D Object Annotation: Accurately label objects in a 3D
                space. Point Cloud Segmentation: Categorize and differentiate
                between various point clusters sourced from LiDAR sensors.
              </p>
            </div>
          </div>
          <div className="box">
            <img src={customDataLabeling} alt="LabelingService" />
            <div className="contents">
              <h5>Custom Data Labeling</h5>
              <p>
                Tailored solutions to meet your unique data labeling needs. Let
                us assist you in achieving your specific AI goals with our
                expertise and resources. We are committed to providing accurate
                and efficient labeling for all types of data.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LabelingServices;
