import React, { useEffect, useRef, useState } from "react";
import { useGloblaContext } from "../context/AutoContext";

function Submenu() {
  const {
    isSubmenuOpen,
    page: { page, links },
    location,
    closeSubmenu,
    scrolling,
  } = useGloblaContext();
  const container = useRef(null);
  const [columns, setColumns] = useState("col-2");

  useEffect(() => {
    setColumns("col-2");
    const submenu = container.current;
    const { center, bottom } = location;
    submenu.style.left = `${center}px`;
    submenu.style.top = `${bottom + 15}px`;
    if (links.length === 3) {
      setColumns("col-3");
    }
    if (links.length > 3) {
      setColumns("col-4");
    }
  }, [page, location, links]);

  return (
    <div
      className={isSubmenuOpen ? "submenu show-submenu" : "submenu"}
      ref={container}
    >
      <h5>{page}</h5>
      <div className={`submenu-links ${columns}`}>
        {links.map((link) => {
          const { label, icon, url } = link;
          return (
            <a
              href={url}
              key={label}
              onClick={(e) => scrolling(e, closeSubmenu)}
            >
              {icon}
              {label}
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default Submenu;